// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar_sidebar__6\\+cw9 {
  position: fixed;
  top: 0;
  left: 0;
  width: 96px;
  height: 100vh;
  background: white;
  border-radius: 0 24px 24px 0;
  padding: 44px 13px 35px;
  z-index: 50;
}

.sidebar_title__R6Wzd {
  font-size: 15px;
  line-height: 1.34;
  color: #000;
  font-weight: 700;
  padding-bottom: 44px;
  font-family: 'Manrope', sans-serif;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 35px;
}

.sidebar_link__jgIEu {
  display: block;
  margin-bottom: 70px;
}

@media (max-width: 1439px) {
  .sidebar_title__R6Wzd {
    padding-bottom: 40px;
    margin-bottom: 30px;
  }

  .sidebar_link__jgIEu {
    margin-bottom: 60px;
  }

  .sidebar_sidebar__6\\+cw9 {
    width: 85px;
  }
}

@media (max-width: 1366px) {
  .sidebar_title__R6Wzd {
    padding-bottom: 35px;
    margin-bottom: 30px;
  }

  .sidebar_link__jgIEu {
    margin-bottom: 50px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/comps/Sidebar/sidebar.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,aAAa;EACb,iBAAiB;EACjB,4BAA4B;EAC5B,uBAAuB;EACvB,WAAW;AACb;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,WAAW;EACX,gBAAgB;EAChB,oBAAoB;EACpB,kCAAkC;EAClC,2CAA2C;EAC3C,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE;IACE,oBAAoB;IACpB,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,WAAW;EACb;AACF;;AAEA;EACE;IACE,oBAAoB;IACpB,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;EACrB;AACF","sourcesContent":[".sidebar {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 96px;\n  height: 100vh;\n  background: white;\n  border-radius: 0 24px 24px 0;\n  padding: 44px 13px 35px;\n  z-index: 50;\n}\n\n.title {\n  font-size: 15px;\n  line-height: 1.34;\n  color: #000;\n  font-weight: 700;\n  padding-bottom: 44px;\n  font-family: 'Manrope', sans-serif;\n  border-bottom: 1px solid rgba(0, 0, 0, 0.2);\n  margin-bottom: 35px;\n}\n\n.link {\n  display: block;\n  margin-bottom: 70px;\n}\n\n@media (max-width: 1439px) {\n  .title {\n    padding-bottom: 40px;\n    margin-bottom: 30px;\n  }\n\n  .link {\n    margin-bottom: 60px;\n  }\n\n  .sidebar {\n    width: 85px;\n  }\n}\n\n@media (max-width: 1366px) {\n  .title {\n    padding-bottom: 35px;\n    margin-bottom: 30px;\n  }\n\n  .link {\n    margin-bottom: 50px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebar": `sidebar_sidebar__6+cw9`,
	"title": `sidebar_title__R6Wzd`,
	"link": `sidebar_link__jgIEu`
};
export default ___CSS_LOADER_EXPORT___;
