// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.teams_chartRow__mWFuH {
  flex-wrap: wrap;
  margin: 0 -15px 25px;
  padding-top: 20px;
}

.teams_chartCol__jW9wg {
  padding: 0 15px;
  width: 50%;
}

.teams_chartRowInner__h\\+zln {
  flex-wrap: wrap;
  margin: 0 -15px 25px;
}

.teams_chartColInner__teBiP {
  padding: 0 15px;
  width: 50%;
}

.teams_factorRow__d1VS9 {
  flex-wrap: wrap;
  margin: 0 -20px -23px;
}

.teams_factorCol__47esk {
  padding: 0 20px 23px;
  width: 25%;
}

.teams_DetailCard__NyrXJ {
  padding: 30px;
  border-radius: 10px;
  box-shadow: unset !important;
}

.teams_cardRow__kzcjD {
  flex-wrap: wrap;
  margin: 0 -15px 5px;
}

.teams_cardCol__twtbF {
  padding: 0 15px 30px;
  width: 20%;
}

.teams_teamRow__ljFNj {
  flex-wrap: wrap;
  margin: 0 -20px;
}

.teams_teamCol__exfWi {
  padding: 0 20px 33px;
  width: 25%;
}

@media (max-width: 1439px) {
  .teams_cardRow__kzcjD {
    margin: 0 -15px;
  }

  .teams_cardCol__twtbF {
    padding: 0 15px 30px;
    width: 25%;
  }

  .teams_teamRow__ljFNj {
    flex-wrap: wrap;
    margin: 0 -15px;
  }

  .teams_teamCol__exfWi {
    padding: 0 15px 30px;
    width: 33.33%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Pages/teams/teams.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,oBAAoB;EACpB,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,UAAU;AACZ;;AAEA;EACE,eAAe;EACf,oBAAoB;AACtB;;AAEA;EACE,eAAe;EACf,UAAU;AACZ;;AAEA;EACE,eAAe;EACf,qBAAqB;AACvB;;AAEA;EACE,oBAAoB;EACpB,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,4BAA4B;AAC9B;;AAEA;EACE,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,oBAAoB;EACpB,UAAU;AACZ;;AAEA;EACE,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,oBAAoB;EACpB,UAAU;AACZ;;AAEA;EACE;IACE,eAAe;EACjB;;EAEA;IACE,oBAAoB;IACpB,UAAU;EACZ;;EAEA;IACE,eAAe;IACf,eAAe;EACjB;;EAEA;IACE,oBAAoB;IACpB,aAAa;EACf;AACF","sourcesContent":[".chartRow {\n  flex-wrap: wrap;\n  margin: 0 -15px 25px;\n  padding-top: 20px;\n}\n\n.chartCol {\n  padding: 0 15px;\n  width: 50%;\n}\n\n.chartRowInner {\n  flex-wrap: wrap;\n  margin: 0 -15px 25px;\n}\n\n.chartColInner {\n  padding: 0 15px;\n  width: 50%;\n}\n\n.factorRow {\n  flex-wrap: wrap;\n  margin: 0 -20px -23px;\n}\n\n.factorCol {\n  padding: 0 20px 23px;\n  width: 25%;\n}\n\n.DetailCard {\n  padding: 30px;\n  border-radius: 10px;\n  box-shadow: unset !important;\n}\n\n.cardRow {\n  flex-wrap: wrap;\n  margin: 0 -15px 5px;\n}\n\n.cardCol {\n  padding: 0 15px 30px;\n  width: 20%;\n}\n\n.teamRow {\n  flex-wrap: wrap;\n  margin: 0 -20px;\n}\n\n.teamCol {\n  padding: 0 20px 33px;\n  width: 25%;\n}\n\n@media (max-width: 1439px) {\n  .cardRow {\n    margin: 0 -15px;\n  }\n\n  .cardCol {\n    padding: 0 15px 30px;\n    width: 25%;\n  }\n\n  .teamRow {\n    flex-wrap: wrap;\n    margin: 0 -15px;\n  }\n\n  .teamCol {\n    padding: 0 15px 30px;\n    width: 33.33%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chartRow": `teams_chartRow__mWFuH`,
	"chartCol": `teams_chartCol__jW9wg`,
	"chartRowInner": `teams_chartRowInner__h+zln`,
	"chartColInner": `teams_chartColInner__teBiP`,
	"factorRow": `teams_factorRow__d1VS9`,
	"factorCol": `teams_factorCol__47esk`,
	"DetailCard": `teams_DetailCard__NyrXJ`,
	"cardRow": `teams_cardRow__kzcjD`,
	"cardCol": `teams_cardCol__twtbF`,
	"teamRow": `teams_teamRow__ljFNj`,
	"teamCol": `teams_teamCol__exfWi`
};
export default ___CSS_LOADER_EXPORT___;
