// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wellbeingCard_wbwrapper__JarUZ {
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
  padding: 30px 20px 13px;
  height: 100%;
}

.wellbeingCard_tagWrapper__wctO9 {
  flex-wrap: wrap;
  margin-bottom: 7px;
}

.wellbeingCard_wbImage__hu8lU {
  margin-bottom: 13px;
  border-radius: 10px;
  min-height: 120px;
  width: 100%;
  height: auto !important;
  object-fit: cover;
}

.wellbeingCard_wbTitle__QYg9V {
  font-size: 20px;
  line-height: 1.3;
  color: #000000;
  margin-bottom: 6px;
}

.wellbeingCard_wbDescription__7\\+Gfk {
  font-size: 14px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.4);
  min-height: 40px;
  margin-bottom: 17px;
}

.wellbeingCard_wbDetails__D65pK {
  font-size: 14px;
  line-height: 20px;
  color: #11845b;
  margin-left: auto;
  max-width: 50px;
}
`, "",{"version":3,"sources":["webpack://./src/comps/WellbeingCard/wellbeingCard.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,oCAAoC;EACpC,uBAAuB;EACvB,YAAY;AACd;;AAEA;EACE,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,iBAAiB;EACjB,WAAW;EACX,uBAAuB;EACvB,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,yBAAyB;EACzB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,cAAc;EACd,iBAAiB;EACjB,eAAe;AACjB","sourcesContent":[".wbwrapper {\n  border-radius: 10px;\n  background-color: rgb(255, 255, 255);\n  padding: 30px 20px 13px;\n  height: 100%;\n}\n\n.tagWrapper {\n  flex-wrap: wrap;\n  margin-bottom: 7px;\n}\n\n.wbImage {\n  margin-bottom: 13px;\n  border-radius: 10px;\n  min-height: 120px;\n  width: 100%;\n  height: auto !important;\n  object-fit: cover;\n}\n\n.wbTitle {\n  font-size: 20px;\n  line-height: 1.3;\n  color: #000000;\n  margin-bottom: 6px;\n}\n\n.wbDescription {\n  font-size: 14px;\n  line-height: 20px;\n  color: rgba(0, 0, 0, 0.4);\n  min-height: 40px;\n  margin-bottom: 17px;\n}\n\n.wbDetails {\n  font-size: 14px;\n  line-height: 20px;\n  color: #11845b;\n  margin-left: auto;\n  max-width: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wbwrapper": `wellbeingCard_wbwrapper__JarUZ`,
	"tagWrapper": `wellbeingCard_tagWrapper__wctO9`,
	"wbImage": `wellbeingCard_wbImage__hu8lU`,
	"wbTitle": `wellbeingCard_wbTitle__QYg9V`,
	"wbDescription": `wellbeingCard_wbDescription__7+Gfk`,
	"wbDetails": `wellbeingCard_wbDetails__D65pK`
};
export default ___CSS_LOADER_EXPORT___;
