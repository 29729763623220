// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home_chartRow__-HeAI {
  flex-wrap: wrap;
  margin: 0 -20px 25px;
  padding-top: 20px;
}

.home_chartCol__hmtIr {
  padding: 0 20px;
  width: 50%;
}

.home_chartRowInner__WqT36 {
  flex-wrap: wrap;
  margin: 0 -15px 25px;
}

.home_chartColInner__KuuRN {
  padding: 0 15px;
  width: 50%;
}

.home_factorRow__MG7no {
  flex-wrap: wrap;
  margin: 0 -20px -23px;
}

.home_factorCol__ozGsr {
  padding: 0 20px 23px;
  width: 25%;
}

.home_DetailCard__YtXb1 {
  padding: 30px;
  border-radius: 10px;
  box-shadow: unset !important;
  height: 100%;
}

.home_calenderCard__B8uBE {
  border-radius: 10px !important;
  background: #ffffff !important;
}

.home_calenderCardInner__\\+FCRj {
  padding: 60px 25px 30px !important;
}

@media (max-width: 1439px) {
  .home_chartRow__-HeAI {
    margin: 0 -15px 25px;
  }

  .home_chartCol__hmtIr {
    padding: 0 15px;
  }

  .home_factorRow__MG7no {
    margin: 0 -15px -22px;
  }

  .home_factorCol__ozGsr {
    padding: 0 15px 22px;
  }

  .home_DetailCard__YtXb1 {
    padding: 30px 25px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Pages/home/home.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,oBAAoB;EACpB,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,UAAU;AACZ;;AAEA;EACE,eAAe;EACf,oBAAoB;AACtB;;AAEA;EACE,eAAe;EACf,UAAU;AACZ;;AAEA;EACE,eAAe;EACf,qBAAqB;AACvB;;AAEA;EACE,oBAAoB;EACpB,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,4BAA4B;EAC5B,YAAY;AACd;;AAEA;EACE,8BAA8B;EAC9B,8BAA8B;AAChC;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE;IACE,oBAAoB;EACtB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,qBAAqB;EACvB;;EAEA;IACE,oBAAoB;EACtB;;EAEA;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".chartRow {\n  flex-wrap: wrap;\n  margin: 0 -20px 25px;\n  padding-top: 20px;\n}\n\n.chartCol {\n  padding: 0 20px;\n  width: 50%;\n}\n\n.chartRowInner {\n  flex-wrap: wrap;\n  margin: 0 -15px 25px;\n}\n\n.chartColInner {\n  padding: 0 15px;\n  width: 50%;\n}\n\n.factorRow {\n  flex-wrap: wrap;\n  margin: 0 -20px -23px;\n}\n\n.factorCol {\n  padding: 0 20px 23px;\n  width: 25%;\n}\n\n.DetailCard {\n  padding: 30px;\n  border-radius: 10px;\n  box-shadow: unset !important;\n  height: 100%;\n}\n\n.calenderCard {\n  border-radius: 10px !important;\n  background: #ffffff !important;\n}\n\n.calenderCardInner {\n  padding: 60px 25px 30px !important;\n}\n\n@media (max-width: 1439px) {\n  .chartRow {\n    margin: 0 -15px 25px;\n  }\n\n  .chartCol {\n    padding: 0 15px;\n  }\n\n  .factorRow {\n    margin: 0 -15px -22px;\n  }\n\n  .factorCol {\n    padding: 0 15px 22px;\n  }\n\n  .DetailCard {\n    padding: 30px 25px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chartRow": `home_chartRow__-HeAI`,
	"chartCol": `home_chartCol__hmtIr`,
	"chartRowInner": `home_chartRowInner__WqT36`,
	"chartColInner": `home_chartColInner__KuuRN`,
	"factorRow": `home_factorRow__MG7no`,
	"factorCol": `home_factorCol__ozGsr`,
	"DetailCard": `home_DetailCard__YtXb1`,
	"calenderCard": `home_calenderCard__B8uBE`,
	"calenderCardInner": `home_calenderCardInner__+FCRj`
};
export default ___CSS_LOADER_EXPORT___;
