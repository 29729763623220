// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progressCard_cardWrap__yuV8k {
  padding: 33px 25px 20px !important;
  border-radius: 10px !important;
  box-shadow: unset !important;
}
`, "",{"version":3,"sources":["webpack://./src/comps/ProgressCard/progressCard.module.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;EAClC,8BAA8B;EAC9B,4BAA4B;AAC9B","sourcesContent":[".cardWrap {\n  padding: 33px 25px 20px !important;\n  border-radius: 10px !important;\n  box-shadow: unset !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardWrap": `progressCard_cardWrap__yuV8k`
};
export default ___CSS_LOADER_EXPORT___;
