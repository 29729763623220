// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.radioCard_radioBtn__1xeQ9 {
  width: calc(33.33% - 10.75px);
  margin: 0 !important;
  border-radius: 17px;
  border: 0.6px solid #979797;
}

.radioCard_radioBtn__1xeQ9 div {
  color: #202224;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  padding: 7px 10px;
}
`, "",{"version":3,"sources":["webpack://./src/comps/RadioCard/radioCard.module.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,oBAAoB;EACpB,mBAAmB;EACnB,2BAA2B;AAC7B;;AAEA;EACE,cAAc;EACd,eAAe;EACf,iBAAiB;EACjB,gBAAgB;EAChB,iBAAiB;AACnB","sourcesContent":[".radioBtn {\n  width: calc(33.33% - 10.75px);\n  margin: 0 !important;\n  border-radius: 17px;\n  border: 0.6px solid #979797;\n}\n\n.radioBtn div {\n  color: #202224;\n  font-size: 14px;\n  line-height: 18px;\n  font-weight: 500;\n  padding: 7px 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"radioBtn": `radioCard_radioBtn__1xeQ9`
};
export default ___CSS_LOADER_EXPORT___;
