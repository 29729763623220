// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header_searchWrap__lMXR0 {
  max-width: 350px;
  position: relative;
}

.header_searchIconWrap__JNdHY {
  width: 24px;
  height: 24px;
  top: 50% !important;
  transform: translateY(-50%);
}

.header_searchIcon__b72VA {
  font-size: 24px;
}

.header_searchInput__pepWH {
  border: 0;
  height: 40px;
  color: #000000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding: 0 15px 0 32px;
}

.header_searchInput__pepWH::placeholder {
  color: rgba(0, 0, 0, 0.4);
}
`, "",{"version":3,"sources":["webpack://./src/comps/Header/header.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,2BAA2B;AAC7B;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,SAAS;EACT,YAAY;EACZ,cAAc;EACd,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".searchWrap {\n  max-width: 350px;\n  position: relative;\n}\n\n.searchIconWrap {\n  width: 24px;\n  height: 24px;\n  top: 50% !important;\n  transform: translateY(-50%);\n}\n\n.searchIcon {\n  font-size: 24px;\n}\n\n.searchInput {\n  border: 0;\n  height: 40px;\n  color: #000000;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 24px;\n  padding: 0 15px 0 32px;\n}\n\n.searchInput::placeholder {\n  color: rgba(0, 0, 0, 0.4);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchWrap": `header_searchWrap__lMXR0`,
	"searchIconWrap": `header_searchIconWrap__JNdHY`,
	"searchIcon": `header_searchIcon__b72VA`,
	"searchInput": `header_searchInput__pepWH`
};
export default ___CSS_LOADER_EXPORT___;
