// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filterButton_filterButton__gO3\\+u {
  width: 20%;
  background: transparent !important;
  border-radius: 0 !important;
  padding: 0 !important;
  height: auto !important;
  border-right: 0.3px solid rgba(151, 151, 151, 0.6) !important;
}

.filterButton_filterButtonTitle__HRYmo {
  color: #202224;
  font-size: 14px;
  font-weight: 700;
  line-height: 68.4px;
  text-align: center;
  padding: 0 24px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.filterButton_filterButton__gO3\\+u > div:last-child {
  inset: 0 auto auto auto !important;
}

.filterButton_filterPopupContent__BP4U7 {
  margin-top: 70px;
  border-radius: 26px !important;
  background: #fff !important;
  box-shadow: 0px 13px 61px 0px rgba(169, 169, 169, 0.37) !important;
  width: 521px !important;
  max-width: 521px !important;
}

.filterButton_filterPopupList__tKx6Z {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 25px 16px;
}
`, "",{"version":3,"sources":["webpack://./src/comps/ButtonFilter/filterButton.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,kCAAkC;EAClC,2BAA2B;EAC3B,qBAAqB;EACrB,uBAAuB;EACvB,6DAA6D;AAC/D;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;EACf,aAAa;EACb,eAAe;EACf,mBAAmB;EACnB,8BAA8B;EAC9B,WAAW;AACb;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,gBAAgB;EAChB,8BAA8B;EAC9B,2BAA2B;EAC3B,kEAAkE;EAClE,uBAAuB;EACvB,2BAA2B;AAC7B;;AAEA;EACE,aAAa;EACb,eAAe;EACf,WAAW;EACX,cAAc;AAChB","sourcesContent":[".filterButton {\n  width: 20%;\n  background: transparent !important;\n  border-radius: 0 !important;\n  padding: 0 !important;\n  height: auto !important;\n  border-right: 0.3px solid rgba(151, 151, 151, 0.6) !important;\n}\n\n.filterButtonTitle {\n  color: #202224;\n  font-size: 14px;\n  font-weight: 700;\n  line-height: 68.4px;\n  text-align: center;\n  padding: 0 24px;\n  display: flex;\n  flex-wrap: wrap;\n  align-items: center;\n  justify-content: space-between;\n  width: 100%;\n}\n\n.filterButton > div:last-child {\n  inset: 0 auto auto auto !important;\n}\n\n.filterPopupContent {\n  margin-top: 70px;\n  border-radius: 26px !important;\n  background: #fff !important;\n  box-shadow: 0px 13px 61px 0px rgba(169, 169, 169, 0.37) !important;\n  width: 521px !important;\n  max-width: 521px !important;\n}\n\n.filterPopupList {\n  display: flex;\n  flex-wrap: wrap;\n  width: 100%;\n  gap: 25px 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterButton": `filterButton_filterButton__gO3+u`,
	"filterButtonTitle": `filterButton_filterButtonTitle__HRYmo`,
	"filterPopupContent": `filterButton_filterPopupContent__BP4U7`,
	"filterPopupList": `filterButton_filterPopupList__tKx6Z`
};
export default ___CSS_LOADER_EXPORT___;
