// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.companyView_chartRow__OJayt {
  flex-wrap: wrap;
  margin: 0 -15px 25px;
  padding-top: 20px;
}

.companyView_chartCol__a8Lh\\+ {
  padding: 0 15px;
  width: 50%;
}

.companyView_chartRowInner__A84oA {
  flex-wrap: wrap;
  margin: 0 -15px 25px;
}

.companyView_chartColInner__uwZzu {
  padding: 0 15px;
  width: 50%;
}

.companyView_factorRow__4kt8e {
  flex-wrap: wrap;
  margin: 0 -20px -23px;
}

.companyView_factorCol__coYV3 {
  padding: 0 20px 23px;
  width: 25%;
}

.companyView_DetailCard__ECUph {
  padding: 30px;
  border-radius: 10px;
  box-shadow: unset !important;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/companyView/companyView.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,oBAAoB;EACpB,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,UAAU;AACZ;;AAEA;EACE,eAAe;EACf,oBAAoB;AACtB;;AAEA;EACE,eAAe;EACf,UAAU;AACZ;;AAEA;EACE,eAAe;EACf,qBAAqB;AACvB;;AAEA;EACE,oBAAoB;EACpB,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,4BAA4B;AAC9B","sourcesContent":[".chartRow {\n  flex-wrap: wrap;\n  margin: 0 -15px 25px;\n  padding-top: 20px;\n}\n\n.chartCol {\n  padding: 0 15px;\n  width: 50%;\n}\n\n.chartRowInner {\n  flex-wrap: wrap;\n  margin: 0 -15px 25px;\n}\n\n.chartColInner {\n  padding: 0 15px;\n  width: 50%;\n}\n\n.factorRow {\n  flex-wrap: wrap;\n  margin: 0 -20px -23px;\n}\n\n.factorCol {\n  padding: 0 20px 23px;\n  width: 25%;\n}\n\n.DetailCard {\n  padding: 30px;\n  border-radius: 10px;\n  box-shadow: unset !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chartRow": `companyView_chartRow__OJayt`,
	"chartCol": `companyView_chartCol__a8Lh+`,
	"chartRowInner": `companyView_chartRowInner__A84oA`,
	"chartColInner": `companyView_chartColInner__uwZzu`,
	"factorRow": `companyView_factorRow__4kt8e`,
	"factorCol": `companyView_factorCol__coYV3`,
	"DetailCard": `companyView_DetailCard__ECUph`
};
export default ___CSS_LOADER_EXPORT___;
