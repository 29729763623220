// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settings_settingCard__g5cSX {
  position: relative;
}

.settings_signOutBtn__hO\\+uB {
  color: #564df3 !important;
}

.settings_reachBtn__CsUrl {
  background: #000000 !important;
  color: #ffffff !important;
  height: 52px !important;
}

.settings_googleBtn__2B1FN {
  background: #000000 !important;
  color: #ffffff !important;
  height: 52px !important;
}

.settings_updatedbtn__7cP4Q {
  height: 52px !important;
  background: #4339f2 !important;
  max-width: 340px !important;
  color: #ffffff !important;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/settings/settings.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,8BAA8B;EAC9B,yBAAyB;EACzB,uBAAuB;AACzB;;AAEA;EACE,8BAA8B;EAC9B,yBAAyB;EACzB,uBAAuB;AACzB;;AAEA;EACE,uBAAuB;EACvB,8BAA8B;EAC9B,2BAA2B;EAC3B,yBAAyB;AAC3B","sourcesContent":[".settingCard {\n  position: relative;\n}\n\n.signOutBtn {\n  color: #564df3 !important;\n}\n\n.reachBtn {\n  background: #000000 !important;\n  color: #ffffff !important;\n  height: 52px !important;\n}\n\n.googleBtn {\n  background: #000000 !important;\n  color: #ffffff !important;\n  height: 52px !important;\n}\n\n.updatedbtn {\n  height: 52px !important;\n  background: #4339f2 !important;\n  max-width: 340px !important;\n  color: #ffffff !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"settingCard": `settings_settingCard__g5cSX`,
	"signOutBtn": `settings_signOutBtn__hO+uB`,
	"reachBtn": `settings_reachBtn__CsUrl`,
	"googleBtn": `settings_googleBtn__2B1FN`,
	"updatedbtn": `settings_updatedbtn__7cP4Q`
};
export default ___CSS_LOADER_EXPORT___;
