// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.circularProgressCard_cardWrap__OcMTM {
  padding: 30px !important;
  border-radius: 10px !important;
  box-shadow: unset !important;
}

@media (max-width: 1439px) {
  .circularProgressCard_cardWrap__OcMTM {
    padding: 30px 20px !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/comps/CircularProgressCard/circularProgressCard.module.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,8BAA8B;EAC9B,4BAA4B;AAC9B;;AAEA;EACE;IACE,6BAA6B;EAC/B;AACF","sourcesContent":[".cardWrap {\n  padding: 30px !important;\n  border-radius: 10px !important;\n  box-shadow: unset !important;\n}\n\n@media (max-width: 1439px) {\n  .cardWrap {\n    padding: 30px 20px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardWrap": `circularProgressCard_cardWrap__OcMTM`
};
export default ___CSS_LOADER_EXPORT___;
