// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.teamCard_cardWrap__Vnmy8 {
  padding: 30px 30px !important;
  border-radius: 10px !important;
  box-shadow: unset !important;
  width: 100% !important;
}

button.teamCard_active__vfpRQ {
  outline: 3px solid #d5d5d5;
}
`, "",{"version":3,"sources":["webpack://./src/comps/TeamCard/teamCard.module.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,8BAA8B;EAC9B,4BAA4B;EAC5B,sBAAsB;AACxB;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".cardWrap {\n  padding: 30px 30px !important;\n  border-radius: 10px !important;\n  box-shadow: unset !important;\n  width: 100% !important;\n}\n\nbutton.active {\n  outline: 3px solid #d5d5d5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardWrap": `teamCard_cardWrap__Vnmy8`,
	"active": `teamCard_active__vfpRQ`
};
export default ___CSS_LOADER_EXPORT___;
